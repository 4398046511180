import React, { useState } from 'react';
import {
    Container,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    List,
    ListItemText,
    IconButton,
    Grid,
    Tooltip,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Avatar,
    CardContent,
    TextareaAutosize,
    ListItem,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';
import { useAppContext } from '../provider/appProvider';
import { Apps } from '@mui/icons-material';
import CustomDialog from '../components/popup';

const AppsScreen = () => {
    const [openDialog, setOpenDialog] = useState(false);
    const [rateDetails, setRateDetails] = useState(null);
    const [newProject, setNewProject] = useState({ category: '', title: '', description: '', youtubeUrl: '', amount: '', priceBrief: '' });
    const { appData, callAPISer, openToast, userDetails } = useAppContext();

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleAddProject = () => {
        callAPISer("addProject", newProject);
        handleCloseDialog();
    };

    const handleOnDelete = (id: any) => {
        callAPISer("deleteProject", { _id: id }, () => {
            openToast({ message: "Project Deleted!!" });
        });
    }

    const handleConfirm = (str: string) => {
        if (str === "Confirm") {

        }
        handleCloseDialog();
    };

    const handleOrder = (task: any) => {
        const payload = { projectId: task._id, buyers: (task.buyers || '') + " " + userDetails._id, email: userDetails.email, userId: userDetails._id, projects: userDetails.projects };

        callAPISer("doOrder", payload, () => { openToast({ message: "Order placed!. We will contact you soon through mail" }) })
    }

    const PriceDetailsView = (priceData: any) => (
        <CardContent>
            <Typography variant="h6" component="div">
                Price Details
            </Typography>
            <Typography variant="body2" color="text.secondary">
                Price: ${priceData.price}
            </Typography>
            <Typography variant="body2" color="text.secondary">
                Payment Type: {priceData.payment}
            </Typography>
        </CardContent>
    );


    return (
        <Container maxWidth="lg">
            <Typography variant="h6" gutterBottom>
                Apps List {userDetails.isAdmin && <Tooltip title="Add Task" aria-label="add-task">
                    <IconButton color="primary" onClick={handleOpenDialog}>
                        <AddIcon />
                    </IconButton>
                </Tooltip>}
            </Typography>
            <List>
                {Boolean(appData.projects && appData.projects.length) ? appData.projects.map((task: any) => (
                    <Accordion key={task._id}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
                                {/* <Avatar aria-label="recipe">
                                    <Apps />
                                </Avatar> */}
                                <Typography variant="h6">{task.title}</Typography>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12}>
                                    <ListItemText primary="Description" />
                                    <Typography variant="body2" color={"text.secondary"} style={{ paddingLeft: 10 }}>{task.description}</Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12}>
                                    <ListItemText primary="Price" />
                                    <Typography variant="body2" color={"text.secondary"} style={{ paddingLeft: 10 }}>{task.amount}</Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12}>
                                    <ListItemText primary="Price Brief" />
                                    <Typography variant="body2" color={"text.secondary"} style={{ paddingLeft: 10 }}>{task.priceBrief}</Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12}>
                                    <ListItemText primary="Demo Video" />
                                    <Grid item xs={12} sm={8}>
                                        <iframe
                                            width="100%"
                                            height="315"
                                            src={'https://www.youtube.com/embed/' + task.youtubeUrl}
                                            title={task.title}
                                            frameBorder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                            allowFullScreen
                                        ></iframe>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} style={{ display: "flex", justifyContent: "end" }}>
                                    <Button disabled={String(task.buyers).includes(userDetails._id)} onClick={() => handleOrder(task)}>Order</Button>
                                    {userDetails.isAdmin && <Button onClick={() => handleOnDelete(task._id)}>Delete</Button>}
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                )) : <ListItem><Typography variant="body2" color="gray">No Apps Available Now</Typography></ListItem>}
            </List>
            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogTitle>Add New Task</DialogTitle>
                <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: '10px', paddingTop: 10 }}>
                    <TextField
                        fullWidth
                        label="Category"
                        value={newProject.category}
                        onChange={(e) => setNewProject({ ...newProject, category: e.target.value })}
                    />
                    <TextField
                        fullWidth
                        label="Title"
                        value={newProject.title}
                        onChange={(e) => setNewProject({ ...newProject, title: e.target.value })}
                    />
                    <TextField
                        multiline
                        fullWidth
                        rows={4}
                        label="Description"
                        value={newProject.description}
                        onChange={(e) => setNewProject({ ...newProject, description: e.target.value })}
                    />
                    <TextField
                        multiline
                        fullWidth
                        label="Amount"
                        value={newProject.amount}
                        onChange={(e) => setNewProject({ ...newProject, amount: e.target.value })}
                    />
                    <TextField
                        multiline
                        fullWidth
                        label="Youtube Embed"
                        value={newProject.youtubeUrl}
                        onChange={(e) => setNewProject({ ...newProject, youtubeUrl: e.target.value })}
                    />
                    <TextareaAutosize minRows={3} value={newProject.priceBrief} onChange={(e) => setNewProject({ ...newProject, priceBrief: e.target.value })} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleAddProject} color="primary">
                        Add Project
                    </Button>
                </DialogActions>
            </Dialog>
            {Boolean(rateDetails) && <CustomDialog title={"Price Details"} content={PriceDetailsView(rateDetails)} onClose={handleConfirm} dialogActionsLabel={["Cancel", "Confirm"]} />}
        </Container>
    );
};

export default AppsScreen;