import * as React from "react";
import HomeView from "../views/home";
import {
  Apps,
  CastForEducation,
  Games,
  Quiz,
  Work,
} from "@mui/icons-material";

export const homeList = [
  {
    title: "Quiz",
    icon: Quiz,
    redirectUrl: "/quiz",
    description:
      "A quiz is a form of assessment or game that tests knowledge about a particular subject or topics. Quizzes can vary in format and difficulty, but typically they include questions that require the participant to select an answer from multiple choices, fill in the blanks, or provide short answers.",
  },
  {
    title: "Apps",
    icon: Apps,
    redirectUrl: "/apps",
    description:
      "Apps are an essential part of our digital ecosystem, and their popularity and usage are expected to continue growing as technology continues to advance and become more integrated into our daily lives.",
  },
  {
    title: "Education",
    redirectUrl: "/education",
    icon: CastForEducation,
    description:
      "Education is a vital component of personal and societal development, and is crucial for ensuring a better future for individuals and society as a whole.",
  },
  // {
  //   title: "Health",
  //   redirectUrl: "/health",
  //   icon: HealthAndSafety,
  //   description:
  //     "Health is a state of physical, mental, and social well-being, not merely the absence of disease or infirmity. It encompasses a wide range of factors that contribute to a person's overall well-being, including genetics, lifestyle choices, environmental factors, and access to healthcare.",
  // },
  // {
  //   title: "Social",
  //   redirectUrl: "/social",
  //   icon: ConnectWithoutContact,
  //   description:
  //     "Social respect is essential for promoting positive relationships, building trust, and creating a sense of community. When individuals feel respected, they are more likely to feel valued and included, leading to greater social cohesion and cooperation.",
  // },
  // {
  //   title: "Companies",
  //   redirectUrl: "/companies",
  //   icon: Business,
  //   description: "Companies play a critical role in the economy by providing goods and services, creating jobs, and driving innovation and growth. They are subject to various regulations and legal requirements, and must also take into account social and environmental responsibilities in their operations.",
  // },
  {
    title: "Freelance Job",
    redirectUrl: "/freelance",
    icon: Work,
    description:
      "Freelancing offers individuals the freedom to work independently, pursue their passions, and have control over their professional lives. It is a popular choice for those seeking flexibility, autonomy, and the ability to work on a variety of projects.",
  },
  {
    title: "Games",
    redirectUrl: "/games",
    icon: Games,
    description:
      "Games are not just pastimes but tools for learning, connecting, and personal growth, making them an essential part of human culture and development.",
  },
];

const HomeScreen = () => {
  return <HomeView title="" dataProvider={homeList} />;
};

export default HomeScreen;
