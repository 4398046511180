import * as React from 'react';
import { CustomCard } from '../components/card/card';
import { Box, Button, Card, CardActions, CardContent, CardHeader, Container, Grid, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';

const GamesListScreen = () => {
    const gamesList = [
        {
            id: 1,
            name: "Business Game",
            description:
                "It is monopoly game to play online.",
            redirectUrl: "/business_game",
        },
    ];

    return (
        <Container maxWidth="md">
            <Typography variant="h6" component="h1" gutterBottom>
                Games List
            </Typography>
            <Grid container spacing={2}>
                {gamesList.map((game) => (
                    <Grid item key={game.id} xs={12} md={6}>
                        <Card sx={{ maxWidth: 345 }} onClick={() => window.location.href = game.redirectUrl}>
                            <CardHeader
                                title={<Typography variant="h6"  color="primary">{game.name}</Typography>}
                            />
                            <CardContent>
                                <Typography variant="body2" color="text.secondary">
                                    {game.description}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Container>
    );
};

export default GamesListScreen;
