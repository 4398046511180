import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import GoogleLoginButton from '../components/googleSign';
import FP from "../assets/fp.png";
import { Image } from '@mui/icons-material';
import GooglePayButton from '../components/googlepay';

export const LoginFormView = (props: any) => {

    React.useEffect(() => {
        setTimeout(() => {
            clearCookie();
            if (((window as any).google as any).accounts)
                ((window as any).google as any).accounts.id.prompt();
        }, 2000)
    }, [])

    const clearCookie = () => {
        const cookies = document.cookie.split(';');
        cookies.forEach(cookie => {
            const [name] = cookie.split('=');
            document.cookie = `${name.trim()}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
        });
    }

    return (
        <Box
            sx={{
                my: 8,
                mx: 4,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <div style={{ width: '100%', height: '100%', display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Image className='boxShadow ripple' component={Avatar} style={{ cursor: "pointer" }} alt="fairpirates logo" src={FP} sx={{ width: '80%', height: '80%' }} onClick={() => {
                    clearCookie();
                    if (((window as any).google as any).accounts)
                        ((window as any).google as any).accounts.id.prompt();
                }} />
            </div>
            <GoogleLoginButton disbleButton handleSuccess={(data: any) => { props.handleSubmit && props.handleSubmit(data) }} />
            {/* <GooglePayButton /> */}
        </Box>
    );
}