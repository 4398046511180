import React, { useRef, useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  Typography,
  Paper,
  Grid,
  RadioGroup,
  Radio,
  FormControlLabel,
  Button,
  TextField,
} from "@material-ui/core";
import { useAppContext } from "../provider/appProvider";
import { Card, CardContent } from "@mui/material";

const useStyles = makeStyles((theme: any) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(3),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

const QuizView = ({ dataProvider, onFinish }: QuestionProps2) => {
  const { openToast } = useAppContext();
  const classes = useStyles();
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [enableSubmit, setEnableSubmit] = useState(false);
  const [selectedAnswer, setSelectedAnswer] = useState("");
  const selectedAnswers = useRef<any>({});
  const answerSubmitted = useRef<any>(false);
  const queOption = dataProvider[currentQuestion].options || [];

  const handleAnswerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const temp = { ...selectedAnswers.current };
    const reslt = dataProvider[currentQuestion].answer === event.target.value;

    if (reslt !== temp["is" + currentQuestion]) {
      temp["total"] = temp["total"] ? temp["total"] + (reslt ? 1 : -1) : reslt ? 1 : 0;
    }
    temp["is" + currentQuestion] = reslt;
    temp[currentQuestion] = event.target.value;
    selectedAnswers.current = temp;
    setSelectedAnswer(event.target.value);
    setEnableSubmit(true);
  };

  const handleNextQuestion = () => {
    if (selectedAnswer === "") {
      openToast({ message: "Please select an answer before moving to the next question.", severity: "info" });
    } else {
      setCurrentQuestion(currentQuestion + 1);
      setSelectedAnswer(selectedAnswers.current[currentQuestion + 1] || "");
      if (!selectedAnswers.current[currentQuestion + 1])
        setEnableSubmit(false);
    }
  };

  const handlePreviousQuestion = () => {
    if (currentQuestion === 0) {
      openToast({ message: "This is the first question.", severity: "info" });
    } else {
      setCurrentQuestion(currentQuestion - 1);
      setSelectedAnswer(selectedAnswers.current[currentQuestion - 1] || "");
      setEnableSubmit(true);
    }
  };

  const handleFinishQuiz = () => {
    answerSubmitted.current = true;
    setEnableSubmit(false);
    // onFinish && onFinish({ correctAnswers: selectedAnswers.current.total, questions: dataProvider.map((obj: any) => ({ answer: obj.answer, question: obj.question })) });
  };

  const renderHtmlText = (id: string, text: string = "") => {
    return <div id={id} dangerouslySetInnerHTML={{ __html: text }}></div>;
  };

  const renderCards = (dataProvider: Array<Question2> = []) => {
    return dataProvider.map((obj, index) => (
      <Card sx={{ width: "100%" }} style={{ margin: "5px 0 5px 0" }}>
        <CardContent>
          <Typography sx={{ mb: 1.5 }} color="text.secondary" gutterBottom>
            <b>Question: </b>
            {renderHtmlText(`fp-quest-${index}`, obj.question)}
          </Typography>
          <Typography sx={{ mb: 1.5 }} color="text.secondary">
            <b>Answer: </b>
            {obj.answer}
          </Typography>
          <Typography sx={{ mb: 1.5 }} color="text.secondary">
            <b>Your Answer: </b>
            {selectedAnswers.current[index]}
          </Typography>
          <Typography sx={{ mb: 1.5 }} color="text.secondary">
            <b>Explanation: </b> {renderHtmlText(`fp-explan-${index}`, obj.explanation)}
          </Typography>
        </CardContent>
      </Card>
    ));
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Typography variant="h6" gutterBottom>
              {currentQuestion + 1}. {dataProvider[currentQuestion].question}
            </Typography>
            {/* {queOption.length && (
              <RadioGroup value={selectedAnswer} onChange={handleAnswerChange}>
                {queOption.map((option, index) => (
                  <FormControlLabel
                    key={index}
                    value={option}
                    control={<Radio color="primary" />}
                    label={option}
                  />
                ))}
              </RadioGroup>
            )} */}
            <TextField
            style={{margin: 10, maxWidth: 150}}
              variant="outlined"
              label="Enter answer"
              fullWidth
              value={selectedAnswer}
              onChange={handleAnswerChange}
            />

            <div className="flex-center gap-10">
              <Button
                variant="contained"
                disabled={currentQuestion === 0}
                color="primary"
                onClick={handlePreviousQuestion}
              >
                Previous
              </Button>
              {currentQuestion < dataProvider.length - 1 ? (
                <Button
                  variant="contained"
                  disabled={!enableSubmit}
                  color="primary"
                  onClick={handleNextQuestion}
                >
                  Next
                </Button>
              ) : (
                <Button
                  variant="contained"
                  disabled={!enableSubmit && answerSubmitted.current}
                  color="primary"
                  onClick={handleFinishQuiz}
                >
                  Finish
                </Button>
              )}
            </div>
          </Paper>
          {answerSubmitted.current && renderCards(dataProvider)}
        </Grid>
      </Grid>
    </div>
  );
};

export default QuizView;
